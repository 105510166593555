import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/b2b-dla-erp.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    laptop: file(relativePath: { eq: "laptop-b2b-questy.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
  }
`;

const B2bdlaerp = ({ data }) => {
  return (
    <Artykul
      title="Integracja B2B z ERP: dlaczego warto?"
      keywords={["b2b dla erp"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Integracja B2B z ERP: dlaczego warto?"
      metaTitle="Integracja B2B z ERP: dlaczego warto?"
      metaDescription="Integracja B2B z ERP: dlaczego warto? ✅ Korzyści wdrożenia w firmie B2B dla ERP • Video 2020 "
    >
      <p style={{
        marginBottom: 25,
      }}>
        Do usprawnienia i przyspieszenia komunikacji z&nbsp;kontrahentem lub partnerem firmy coraz chętniej wykorzystują rozwiązania 
        takie jak<strong> platformy B2B dla ERP</strong>. Ich zadaniem jest dostarczenie użytkownikom kluczowych informacji o&nbsp;produktach – ich cenach, 
        statusach zamówień czy stanach magazynowych. Proces wymiany danych w integracji B2B z ERP jest całkowicie automatyczny, więc 
        <strong> handlowiec nie musi brać udziału w&nbsp;pełnym cyklu</strong>.
      </p>
      <ResponsiveEmbed
        src="https://www.youtube.com/embed/sS8OF33PvTU"
        allowFullScreen
        ratio="16:9"
      />
      <p style={{
        marginTop: 25,
      }}>
        Integracja B2B z ERP<strong> przyspiesza transfer informacji </strong>o kontrahentach, produktach, rozliczeniach i stanach magazynowych. 
        Do&nbsp;systemu spływają na bieżąco aktualizowane dane z systemu ERP. Zmiana cen czy liczby produktów sprawi z kolei, że nowe 
        dane pojawią się w platformie B2B po synchronizacji.
      </p>
      <p>
       Możliwy jest także przepływ informacji w drugą stronę, gdyż w platformę B2B wbudowany jest automatyczny mechanizm 
       eksportowania zamówień do systemu zewnętrznego. W takim cyklu synchronizacji, do systemu ERP B2B na bieżąco przesyła 
       wszystkie kluczowe informacje – nie wymaga to zbędnego angażowania pracowników i wpływa na oszczędność czasu.
      </p>
      <h2 style={{
        marginTop: 25,
        marginBottom: 15,
      }}>Integracja B2B z ERP – co&nbsp;zyskujesz?</h2>
      <p>
        <ul>
          <li>
            Wgląd do: faktur z ERP, magazynów, rozliczeń, cen i rabatów kontrahenta nadanych w&nbsp;systemie ERP,</li>
          <li>Eksportowanie zamówień do systemu ERP z&nbsp;B2B,</li>
          <li>Eksportowanie nowych klientów z programu handlowego z ich pierwszym zamówieniem,</li>
          <li>Importowanie kontrahentów,</li>
          <li>Importowanie produktów i usług,</li>
          <li>Raportowanie sprzedaży.</li>
        </ul>
      </p>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Sprawna integracja B2B z ERP dla Twojego biznesu</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami już teraz i uzyskaj dostęp do darmowej 30-dniowej wersji próbnej platformy B2B</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować integrację B2B z ERP
            </Link>
          </div>
          </div>
    </section>


      <h2 style={{
        marginTop: 25,
        marginBottom: 15,
      }}>Jak działa integracja B2B z&nbsp;ERP?</h2>
      <ol>
        <li>
          <strong>Baza kontrahentów</strong>
        </li>
        <p>
          Z systemu ERP do B2B<strong> pobierane są najważniejsze informacje na temat kontrahentów</strong>. Wśród nich znajdziesz, 
          między innymi, nazwę firmy, NIP, adres czy szczegóły współpracy (polityka rabatowa, domyślna forma płatności 
          a także przypisany do klienta poziom cenowy). Nowy kontrahent zostaje automatycznie przekazany do platformy B2B.
        </p>
        <p style={{
        marginBottom: 25,
      }}>
          Z drugiej strony, dodanie klienta w systemie B2B nie skutkuje automatycznym przekazaniem go do systemu ERP. 
          Dlaczego? Handlowcy w czasie rozmów z potencjalnymi klientami często dodają do bazy wiele kontaktów. Z częścią 
          z nich prawdopodobnie nigdy nie dojdzie do sprzedaży. Warunkiem automatycznego przesłania kontrahenta do systemu 
          ERP z platformy B2B jest złożenie przez niego zamówienia. Wówczas przekazane zostanie zarówno samo zamówienie, 
          jak i dane nowego klienta.
        </p>
        <li>
          <strong>Faktury i płatności</strong>
        </li>
        <p style={{
        marginBottom: 25,
      }}>
          B2B dla ERP oznacza także<strong> nowe możliwości dotyczące rozliczeń</strong>. Pracownicy otrzymują dostęp do faktur oraz 
          płatności powiązanych ze swoimi klientami. Ułatwia to, na przykład, weryfikację opóźnień w&nbsp;płatnościach 
          u&nbsp;poszczególnych kontrahentów.
        </p>
        <li>
          <strong>Stany magazynowe i produkty</strong>
        </li>
        <p>
          Platforma B2B pozwala na tworzenie zamówień nie tylko przez klienta, 
          ale także przez przedstawiciela handlowego.
        </p>
        <p>
         Integracja B2B z oprogramowaniem ERP sprawia, że<strong> handlowiec jest na bieżąco z&nbsp;informacjami na 
         temat wszystkich produktów z oferty</strong>. W dowolnym momencie sprawdzi on dostępność danego 
         produktu w&nbsp;magazynie oraz cenę z uwzględnieniem rabatów przypisanych do wybranego kontrahenta.
        </p>
        <p style={{
        marginBottom: 25,
      }}>
         W czasie tworzenia zamówienia lub oferty<strong> system zasugeruje ceny, w jakich handlowiec powinien zrealizować 
         sprzedaż</strong>. Uwzględnione zostaną wartości i rabaty kontrahenta. Przedstawiciel zaoszczędzi 
         dzięki temu sporo cennego czasu.
        </p>
        <Lightbox
          style={{
            maxWidth: 1200,
            marginBottom: 25,
          }}
          images={[data.laptop]}
          alts={["Platforma B2B dla ERP - produkty"]}
        />
        <li>
          <strong>Eksportowanie zamówień</strong>
        </li>
        <p>
          B2B dla ERP to również istotne wsparcie na&nbsp;każdym kroku procesu obsługi
          zamówień. Wszystkie składane przez klientów<strong> zamówienia są natychmiast
          przesyłane do&nbsp;systemu ERP</strong>.
        </p>
        <p style={{
        marginBottom: 25,
      }}>
          Zamówienia w Twojej firmie składane są telefonicznie lub mailowo? Z B2B dla ERP obsłużysz je znacznie 
          sprawniej. Pracownicy odpowiedzialni za obsługę zamówień nie muszą czekać na uzupełnienie w systemie 
          danych przez handlowca, ponieważ te od razu trafiają do systemu ERP. Dzięki temu mogą przygotowywać 
          zamówienia do wysyłki w&nbsp;czasie rzeczywistym. To z kolei<strong> skraca czas ich realizacji i redukuje 
          możliwość pomyłki </strong>przy ręcznym przepisywaniu danych.
        </p>
        <li>
          <strong>Raportowanie</strong>
        </li>
        <p style={{
        marginBottom: 25,
      }}>
          Potrzebujesz raportu przedstawiającego sprzedaż kwartalną? Chcesz dowiedzieć się, który produkt jest 
          hitem w tym sezonie? Zweryfikować, którzy klienci nie uiścili jeszcze opłaty za faktury? Dzięki integracji 
          B2B z ERP w swojej platformie<strong> możesz błyskawicznie generować raporty z niemal każdej informacji z bazy</strong>.
        </p>
      </ol>
      <h2 style={{
        marginTop: 15,
        marginBottom: 15,
      }}>Proces integracji B2B z&nbsp;ERP</h2>
      <p>
        <strong>Kluczowe dla Twojego biznesu informacje na bieżąco wymieniane są pomiędzy systemami</strong>. 
        Wszystkie dane transferowane są z systemu ERP do systemu B2B. Analogicznie, nowi klienci, 
        faktury czy produkty dodane w systemie ERP płynnie synchronizują się i wyświetlają w systemie B2B.
      </p>
      <p>
        Integracja działa obustronnie. Oznacza to, że&nbsp;również nowe dane wprowadzone do platformy B2B zostają przekazane do systemu ERP.
      </p>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Sprawna integracja B2B z ERP dla Twojego biznesu</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami już teraz i uzyskaj dostęp do darmowej 30-dniowej wersji próbnej platformy B2B

</p>
      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
             Chcę wypróbować integrację B2B z ERP
            </Link>
          </div>
          </div>
    </section>
      <br />
    </Artykul>
  );
};

export default B2bdlaerp;
